
import { Transition } from "@headlessui/react";
import NcImage from "components/NcImage/NcImage";
import { Component } from "react";

export interface SubLandingProps {
  className?: string;
  heading: string;
  subHeading: string;
  blurb: string;
  featuredImage: string;
  imageAlt: string;
}

export class SubLanding extends Component<SubLandingProps> {
  public constructor(props: SubLandingProps){
  super(props);
  }
  render() {

  return (
    <div className={`nc-SectionLargeSlider relative`}>
        <Transition
        appear={true}
        as="div"
        className={`nc-CardLarge1 relative flex flex-col-reverse md:flex-row justify-end ${this.props.className}`}
        show={true}
      >
        <div className="md:absolute md:left-0 md:top-1/2 md:transform md:-translate-y-1/2 w-full -mt-8 md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5">
          <Transition.Child
            as='div'
            enter="transform nc-will-change-transform transition-all duration-500"
            enterFrom="translate-y-4 opacity-0"
            enterTo="translate-y-0 opacity-100"
          >
            <div className="bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg shadow-lg rounded-3xl space-y-3 sm:space-y-5 !border-opacity-0 --  nc-dark-box-bg">
            <NcImage
              prevImageHorizontal
              containerClassName="aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
              className="absolute inset-0 object-cover rounded-3xl"
              src={this.props.featuredImage ? this.props.featuredImage : undefined}
              alt={this.props.imageAlt}
            />
            </div>
          </Transition.Child>
        </div>
        <Transition.Child
          as='div'
          className="w-full md:w-4/5 lg:w-2/3 text-align-center inline-block align-middle"
          enter="transform nc-will-change-transform transition-all duration-500 delay-200"
          enterFrom="translate-y-4 scale-105 opacity-0"
          enterTo="translate-y-0 scale-100 opacity-100"
        >
            {/* TODO CHANGE IMAGE */}
              <div className="p-4 sm:p-8 xl:py-14 md:px-10 bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg shadow-lg rounded-3xl space-y-3 sm:space-y-5 !border-opacity-0 --  nc-dark-box-bg">
                <p className="text-justify inline-block align-middle">{this.props.blurb}</p>
                {/* Fix this inline style cant get margin to work with tailwindcss */}
              </div>
        </Transition.Child>
      </Transition>
    </div>
  );
  }
};

export default SubLanding;
