import { Component } from "react";

export interface HeadingsProps {
  fontClass?: string;
  subHeading?: string;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  className?: string;
}
export class Headings extends Component<HeadingsProps> {
  public constructor(props: HeadingsProps){
  super(props);
  }
  render() {

  return (
    <div
      className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${this.props.className}`}
    >
      <div
        className={
          this.props.isCenter ? "text-center w-full max-w-2xl mx-auto " : "max-w-2xl"
        }
      >
        <h2 className={`text-3xl md:text-4xl font-semibold ${this.props.className}`}>
          {this.props.children || `Section Heading`}
        </h2>
        {this.props.subHeading && (
          <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">
            {this.props.subHeading}
          </span>
        )}
      </div>
    </div>
  );
  }
};

export default Headings;

