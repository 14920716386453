import React from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Linking } from "react-native";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
import { useLocation } from "react-router-dom";
export interface ContactUsProps {

}

const ContactUs: React.FC<ContactUsProps> = ({
}) => {

  return (
    <ButtonPrimary onClick={() => {
        //RouteChangeTracker(true, "ContactUsHref");
        window.location.href='/contact-us';
      }}>Contact Us</ButtonPrimary>
  );
};

export default ContactUs;
