import __teams from "./jsons/__users.json";

import { PostTeamType } from "./types";

let as: string[] = [];

const NEWS_TEAMS: PostTeamType[] = __teams.map((item, index) => ({
  ...item,
  avatar: as[index],
}));

export { NEWS_TEAMS };
