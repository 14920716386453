import React, { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Contact from "components/Contact/Contact";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
export interface PageContactProps {
  className?: string;
}


const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  RouteChangeTracker();
  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Contact Us || Rapid Consulting Engineers || Civil Non-Destructive Testing</title>
      </Helmet>
      <BgGlassmorphism />
      <div className={`nc-SectionLargeSlider relative`}>
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <Contact />
      </div>
      </div>
    </div>
  );
};

export default PageContact;
