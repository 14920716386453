import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Contact from "components/Contact/Contact";
import { ANDT } from "data/services";
import SectionSliderServices from "components/SectionSliderServices/SectionSliderServices";
import Headings from "components/Heading/Headings";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { ANDTPageType } from "containers/PageANDT/PageANDT";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
export interface PageServicesProps {
  className?: string;
}


let serviceParents: ANDTPageType[] = ANDT.filter((service) => service.parentService === true)

const PageServices: FC<PageServicesProps> = ({ className = "" }) => {
  //TODO ADD Google Analytics to this page and to buttons throughout the website might need custom function as RouteChangeTracker function breaks the modal view.
  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Our Services || Rapid Consulting Engineers</title>
      </Helmet>
      
      <BgGlassmorphism />
      <div className={`nc-SectionLargeSlider relative`}>
      
        <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <Headings subHeading="Leading experts in everything Non-Destructive Testing &amp; Laboratory Services">Our Services</Headings>
          <div>
            <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
              <div className="flex space-x-2.5">
                {/* <ModalTags tags={NEWS_CATEGORIES} /> */}
              </div>
              <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
              <div className="flex justify-end">
              </div>
            </div>

            {/* === SECTION 5 === */}
            {/* TODO fix the small window narrowing the cols to i think 5 instead of 3 full width */}
            <SectionSliderServices
              services={serviceParents}
            />
            <Contact />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageServices;
