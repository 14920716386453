import React from "react";
import { BrowserRouter, Switch, Route} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "components/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import PageNews from "containers/PageNews/PageNews";
import PageTeam from "containers/PageTeam/PageTeam";
import PageSearch from "containers/PageSearch/PageSearch";
import PageSingle from "containers/PageSingle/PageSingle";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageCareer from "containers/PageCareer/PageCareer";
import PageServices from "containers/PageServices/PageServices";

import PageANDT from "containers/PageANDT/PageANDT";



import HeaderContainer from "containers/HeaderContainer/HeaderContainer";
import PageHome from "containers/PageHome/PageHome";
import MediaRunningContainer from "containers/MediaRunningContainer/MediaRunningContainer";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  
  


  
  { path: "/about-us/", exact: true, component: PageAbout },

  { path: "/our-services/", exact: true, component: PageServices },
  { path: "/author/ash/", exact: true, component: PageHome },

  
  //
  // New Parent Category Pages
  // 
  //        NDT          //
  { path: "/advanced-non-destructive-testing", exact: true, component: PageANDT }, //Old page URL but new NDT parent site.
  //        Structural Investigations         //
  { path: "/advanced-non-destructive-testing/", exact: true, component: PageANDT },
  { path: "/structural-investigations", exact: true, component: PageANDT },
  //        Lab Testing         //
  { path: "/laboratory-services", exact: true, component: PageANDT },
  //
  // New Child Category Pages
  // 
  //        NDT          //
  { path: "/advanced-non-destructive-testing/gpr-scanning", exact: true, component: PageANDT },
  { path: "/advanced-non-destructive-testing/ultrasonic-testing", exact: true, component: PageANDT },
  // { path: "/advanced-non-destructive-testing/as-built-details", exact: true, component: PageANDT },
  // { path: "/advanced-non-destructive-testing/quality-control", exact: true, component: PageANDT },
  // { path: "/advanced-non-destructive-testing/deterioration-assessments", exact: true, component: PageANDT },
  //        Structural Investigations         //
  { path: "/structural-investigations/as-built-inspections-and-detailing", exact: true, component: PageANDT },
  { path: "/structural-investigations/quality-control", exact: true, component: PageANDT },
  { path: "/structural-investigations/deterioration-assessments", exact: true, component: PageANDT },
  //        Lab Testing         //
  { path: "/laboratory-services/testing", exact: true, component: PageANDT },
  { path: "/laboratory-services/compressive-strength", exact: true, component: PageANDT },
  { path: "/laboratory-services/carbonation", exact: true, component: PageANDT },


  //
  //  Below are the list of URLS from old website
  //
  { path: "/building-inspections-melbourne/", exact: true, component: PageServices },
  { path: "/civil-structural-engineering-design-melbourne/", exact: true, component: PageServices },
  { path: "/concrete-cancer/", exact: true, component: PageServices },
  { path: "/engineering-consultant-melbourne/", exact: true, component: PageServices },
  { path: "/forensic-engineering-melbourne/", exact: true, component: PageServices },
  { path: "/forensic-inspection-melbourne/", exact: true, component: PageServices },
  { path: "/forensic-inspection-melbourne-2/", exact: true, component: PageServices },
  { path: "/fr-inspection/", exact: true, component: PageServices },
  { path: "/geotechnical-investigations-melbourne/", exact: true, component: PageServices },
  { path: "/geotechnical/", exact: true, component: PageServices },
  { path: "/geot/", exact: true, component: PageServices },
  { path: "/gpr-concrete-scanning-melbourne/", exact: true, component: PageServices },
  { path: "/gpr-concrete-scanning/", exact: true, component: PageServices },
  { path: "/gpr-concrete-scanning-melbourneold/", exact: true, component: PageServices },
  { path: "/ground-penetrating-radar/", exact: true, component: PageServices },
  { path: "/non-destructive-testing-melbourne/", exact: true, component: PageServices },
  { path: "/sample-page/", exact: true, component: PageHome },
  { path: "/our-services-2/", exact: true, component: PageServices },
  { path: "/post-tension-pt-scanning-melbourne/", exact: true, component: PageServices },
  { path: "/privacy-policy/", exact: true, component: PageHome },
  { path: "/slab-scan/", exact: true, component: PageServices },
  { path: "/soil-classification-melbourne/", exact: true, component: PageServices },
  { path: "/soil-test-melbourne/", exact: true, component: PageServices },
  { path: "/structural-engineer-melbourne/", exact: true, component: PageServices },
  { path: "/structural-design-melbourne/", exact: true, component: PageServices },
  { path: "/structural-design-melbourne-2/", exact: true, component: PageServices },
  { path: "/structural-inspection-melbourne/", exact: true, component: PageServices },
  { path: "/thank-you/", exact: true, component: PageHome },
  { path: "/thank-you-3/", exact: true, component: PageHome },
  { path: "/thank-you-1/", exact: true, component: PageHome },
  { path: "/ultrasonic-concrete-testing-melbourne/", exact: true, component: PageServices },
  { path: "/what-is-ndt-and-its-benefits-for-you/", exact: true, component: PageServices },
  { path: "/why-soil-investigations-are-important/", exact: true, component: PageServices },
  { path: "/lead/", exact: true, component: PageHome },





  





  { path: "/career/", exact: true, component: PageCareer },

  { path: "/news/", component: PageNews }, //TODO turn into news header page.
  //{ path: "/news/:slug", exact: true, component: PageNews }, //TODO make into individual News pages.
  { path: "/news/article/", exact: true, component: PageSingle },
  { path: "/article/", exact: true, component: PageSingle },
  // Use for individual news pages.





  
  { path: "/news/Testing-without-the-destruction", exact: true, component: PageSingle },
  { path: "/news/Why-Test-Concrete?", exact: true, component: PageSingle },
  { path: "/news/Details-are-Everything", exact: true, component: PageSingle },
  { path: "/news/What-is-GPR?", exact: true, component: PageSingle },
  { path: "/news/Structural-Investigations-Save-Money", exact: true, component: PageSingle },
  { path: "/news/Testing-concrete", exact: true, component: PageSingle },
  { path: "/news/Ultrasonic-testing-and-why-you-need-it", exact: true, component: PageSingle },
  { path: "/news/What-can-your-concrete-support?", exact: true, component: PageSingle },
  { path: "/news/What's-involved-in-laboratory-testing?", exact: true, component: PageSingle },

  { path: "/contact-us/", exact: true, component: PageContact },



  //{ path: "/search/", exact: true, component: PageHome }, //TODO fix to search blog names // Change back to PageSearch one day
  //{ path: "/team/:slug/", exact: true, component: PageAbout }, //TODO make Company user profiles. // Change back to PageTeam one day if we want the team page.
  { path: "/page404/", exact: true, component: Page404 },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <HeaderContainer />
      <Switch>
        {pages.map(({ component, path, exact, strict}) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
              strict={strict}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
      {/* MEDIA */}
      <MediaRunningContainer />
    </BrowserRouter>
  );
};

export default Routes;
