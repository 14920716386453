import Logo from "components/Logo/Logo";
//import SocialsList1 from "components/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus?: CustomLink[];
  paragraph?: string;
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    paragraph: "RCE is a technologically advanced company offering a wide range of concrete scanning services. We understand that there are differences in the requirements of each project; therefore, we design a testing program tailored to suit the needs of each specific client. With our testing program, you can rest assured we will accurately obtain the information you require using best-in-class equipment and expertise.",
  },
  {
    id: "1",
    title: "Quick links",
    menus: [
      { href: "/structural-investigations", label: "Structural Investigations" },
      { href: "/advanced-non-destructive-testing", label: "Non-Destructive Testing" },
      { href: "/advanced-non-destructive-testing/gpr-scanning", label: "GPR Scanning" },
      { href: "/advanced-non-destructive-testing/ultrasonic-testing", label: "Ultrasonic Testing" },
      { href: "/structural-investigations/as-built-inspections-and-detailing", label: "As-Built Inspections and Detailing" },
      { href: "/structural-investigations/quality-control", label: "Quality Control" },
      { href: "/structural-investigations/deterioration-assessments", label: "Deterioration Assessments" },
    ],
  },
  {
    id: "2",
    title: "Contact Us",
    menus: [
      { href: "https://goo.gl/maps/d6QdsPYi6nqGWFvx5", label: "Unit 6, 83-87 Dover St, Richmond VIC 3121" },
      { href: "tel:+611300972743", label: "1300 972 743" },
      { href: "mailto:info@rapidengineers.com.au", label: "info@rapidengineers.com.au" },
    ],
  },
  // {
  //   id: "3",
  //   title: "Locate Us",
  //   menus: [
  //     { href: "#", label: "TODO Add Map Code" },
  //   ],
  //   //map: initMap()
  // },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm mt-3">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        {menu.menus ? <ul className="mt- space-y-2">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
          </ul>
          : <p className="text-justify text-neutral-6000 dark:text-neutral-300">{menu.paragraph}</p>} 
          {/* //TODO try to make lineheightdo something leading-5 should work */}
      </div>
    );
  };

  return (
    <div className="nc-Footer relative pt-10 lg:pt-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 mb-10 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
      </div>
      <div className="bg-gradient-to-r from-rapidOrange-900 to-rapidOrange-600"> {/*TODO Gradient */}
        <div className="container pt-1 pb-1 text-neutral-6000 dark:text-neutral-300"><p className='inline-block rce-text-rapidorange'>v1.2</p>©2021 Rapid Consulting Engineers Pty Ltd <a href="/our-services/">OUR SERVICES</a></div>
      </div>
    </div>
    
  );
};

export default Footer;
