import React from "react";

const LogoSvg = () => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="200.000000pt" height="70.000000pt" viewBox="0 0 8196.000000 1817.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,1817.000000) scale(0.100000,-0.100000)"
fill="#db6212" stroke="none">
<path d="M49590 9085 l0 -9085 320 0 320 0 0 9085 0 9085 -320 0 -320 0 0
-9085z"/>
<path d="M27065 17469 c-1856 -75 -3501 -637 -4812 -1646 -1634 -1256 -2711
-3215 -2987 -5433 -46 -362 -58 -555 -63 -1002 -6 -453 3 -689 38 -1073 158
-1721 745 -3261 1692 -4440 281 -350 656 -728 997 -1006 1445 -1179 3408
-1773 5735 -1736 395 7 559 15 885 48 1791 177 3326 825 4595 1940 71 63 308
289 527 503 357 348 397 391 386 407 -31 43 -3084 3438 -3093 3438 -5 1 -181
-169 -390 -378 -209 -208 -425 -414 -480 -457 -544 -430 -1327 -744 -2140
-860 -153 -21 -619 -30 -827 -15 -675 50 -1225 213 -1693 503 -876 544 -1381
1563 -1421 2868 -27 857 224 1725 686 2370 615 860 1565 1300 2806 1300 828 0
1584 -207 2164 -593 208 -138 300 -220 816 -730 100 -99 186 -178 191 -176 19
7 3183 3144 3183 3155 0 17 -822 817 -1006 979 -1020 894 -2154 1496 -3409
1809 -757 189 -1512 260 -2380 225z"/>
<path d="M5000 16930 l0 -230 666 0 c366 0 664 -4 662 -8 -1 -5 -214 -248
-472 -539 -257 -292 -469 -533 -470 -536 0 -3 211 -251 469 -551 259 -301 472
-551 473 -556 2 -7 -287 -10 -822 -10 l-826 0 0 -1080 0 -1080 826 0 c513 0
824 -4 822 -9 -1 -6 -214 -248 -471 -540 -257 -292 -468 -534 -468 -538 0 -4
210 -253 468 -552 257 -299 470 -547 471 -552 2 -5 -341 -9 -822 -9 l-826 0 0
-1060 0 -1060 826 0 c454 0 824 -4 822 -8 -1 -5 -214 -248 -473 -541 -258
-293 -469 -535 -469 -538 1 -3 213 -250 470 -549 258 -299 471 -549 472 -554
3 -7 -284 -10 -822 -10 l-826 0 0 -1075 0 -1075 825 0 c454 0 825 -4 825 -8 0
-5 -213 -251 -474 -546 l-474 -538 467 -541 466 -542 1773 -3 1772 -2 0 2285
0 2285 468 0 468 0 1778 -2285 1779 -2285 2373 0 2374 0 0 718 -1 717 -1634
1975 c-899 1086 -1635 1977 -1635 1980 0 3 28 21 63 40 315 172 663 408 942
641 126 105 390 364 503 492 750 854 1120 1907 1160 3304 15 509 -15 906 -99
1343 -277 1429 -1096 2660 -2299 3455 -909 601 -1974 930 -3225 995 -119 6
-1622 10 -4032 10 l-3843 0 0 -230z m7707 -4206 c179 -26 356 -95 488 -189 75
-54 199 -181 252 -260 175 -260 272 -646 229 -910 -41 -247 -141 -453 -298
-613 -141 -143 -299 -226 -543 -285 -90 -21 -96 -21 -1532 -24 l-1443 -4 0
1151 0 1150 1371 0 c1187 0 1385 -2 1476 -16z"/>
<path d="M34960 9325 l0 -7835 6180 0 6180 0 0 2090 0 2090 -3970 0 -3970 0 0
970 0 970 3970 0 3970 0 0 1695 0 1695 -3970 0 -3970 0 0 970 0 970 3970 0
3970 0 0 2110 0 2110 -6180 0 -6180 0 0 -7835z"/>
<path d="M2556 16143 c-361 -402 -497 -559 -490 -569 5 -7 230 -251 499 -543
l490 -529 810 -1 c446 0 816 3 823 7 9 6 -126 169 -455 551 -258 299 -469 548
-470 555 -1 6 209 250 467 541 368 417 465 531 452 537 -9 4 -379 7 -822 7
l-805 1 -499 -557z"/>
<path d="M53130 16559 c-246 -9 -575 -42 -652 -65 -17 -5 -18 -98 -18 -1775
l0 -1769 240 0 240 0 0 775 0 775 208 0 c322 -1 459 -19 587 -80 83 -39 147
-92 201 -164 96 -127 134 -231 223 -604 71 -297 129 -505 172 -619 l32 -83
255 0 c199 0 253 3 249 13 -73 165 -115 308 -231 772 -46 187 -100 381 -120
431 -94 234 -231 390 -414 471 l-56 25 81 34 c370 153 590 454 610 834 10 193
-32 383 -120 540 -57 101 -195 239 -302 301 -209 121 -419 172 -775 189 -102
5 -192 9 -200 8 -8 -1 -103 -4 -210 -9z m531 -383 c312 -56 509 -217 574 -471
21 -81 21 -254 0 -334 -48 -187 -162 -326 -341 -414 -167 -83 -249 -96 -636
-104 l-318 -6 0 656 0 655 71 12 c189 32 489 35 650 6z"/>
<path d="M59465 16559 c-223 -7 -343 -17 -562 -46 l-123 -16 0 -1773 0 -1774
240 0 240 0 0 716 0 716 28 -6 c93 -20 341 -29 495 -18 509 35 878 207 1093
508 204 285 243 767 90 1099 -163 352 -554 568 -1071 590 -66 3 -140 7 -165 8
-25 2 -144 0 -265 -4z m400 -379 c230 -27 396 -94 519 -211 129 -122 187 -269
187 -475 1 -407 -239 -669 -677 -740 -157 -25 -458 -19 -606 12 l-28 6 0 688
c0 566 2 689 13 693 15 6 175 25 247 30 106 8 261 6 345 -3z"/>
<path d="M63770 16559 c-124 -4 -281 -12 -350 -18 -148 -14 -364 -39 -402 -47
l-28 -6 0 -1764 0 -1764 23 -4 c78 -17 325 -29 677 -33 331 -4 433 -2 560 11
332 35 619 113 865 236 477 237 779 669 881 1260 25 145 30 541 10 697 -49
376 -184 667 -421 912 -282 292 -659 453 -1180 505 -189 19 -350 23 -635 15z
m455 -379 c690 -58 1119 -391 1251 -969 70 -311 50 -704 -52 -1010 -173 -518
-598 -826 -1233 -892 -111 -11 -581 -7 -678 7 l-43 6 0 1408 0 1408 23 5 c159
38 509 55 732 37z"/>
<path d="M56377 16458 c-14 -40 -284 -815 -602 -1723 -318 -907 -588 -1680
-601 -1717 l-23 -68 253 0 252 0 190 560 189 560 650 0 650 0 195 -560 195
-560 257 0 c154 0 258 4 258 9 0 8 -1221 3491 -1245 3554 -7 16 -30 17 -300
17 l-293 0 -25 -72z m389 -646 c30 -106 146 -457 259 -779 113 -322 205 -588
205 -590 0 -1 -245 -3 -545 -3 -300 0 -545 2 -545 4 0 2 81 237 181 522 194
558 244 713 309 954 22 85 44 164 47 175 5 15 10 6 20 -35 8 -30 39 -142 69
-248z"/>
<path d="M61690 14740 l0 -1790 240 0 240 0 0 1790 0 1790 -240 0 -240 0 0
-1790z"/>
<path d="M544 11828 c-248 -277 -472 -526 -498 -554 l-46 -52 497 -536 496
-536 824 0 c452 0 823 3 823 8 0 4 -211 252 -469 551 l-469 543 469 532 c258
292 469 534 469 539 0 4 -370 7 -823 7 l-822 0 -451 -502z"/>
<path d="M53855 10844 c-294 -40 -580 -140 -802 -280 -257 -163 -489 -426
-622 -704 -217 -455 -249 -1069 -81 -1571 134 -403 414 -730 784 -916 188 -95
404 -159 629 -188 137 -17 474 -20 628 -5 214 22 445 71 588 127 59 23 70 30
65 46 -3 10 -26 93 -50 185 -25 91 -46 168 -49 170 -2 2 -32 -7 -67 -21 -235
-93 -584 -141 -855 -118 -378 33 -662 153 -881 373 -202 204 -318 450 -368
778 -20 136 -20 410 0 552 70 491 310 845 704 1042 380 190 956 203 1385 31
38 -15 70 -25 72 -23 5 5 115 376 115 387 0 13 -189 73 -313 100 -168 36 -336
51 -567 50 -119 -1 -260 -7 -315 -15z"/>
<path d="M56737 10845 c-774 -125 -1301 -704 -1412 -1549 -20 -154 -20 -477 0
-632 91 -694 494 -1224 1079 -1419 206 -69 282 -79 566 -79 228 0 266 2 360
22 231 50 429 132 610 253 131 86 325 282 413 414 162 246 265 540 308 880 19
149 16 494 -5 640 -62 435 -220 771 -485 1035 -221 220 -466 351 -786 420
-131 29 -510 37 -648 15z m383 -375 c300 -34 547 -178 734 -427 270 -360 371
-899 270 -1437 -36 -192 -131 -424 -239 -586 -71 -107 -222 -253 -329 -319
-176 -109 -344 -154 -566 -154 -151 0 -230 12 -351 54 -264 90 -488 296 -633
581 -275 541 -236 1320 89 1808 233 351 611 528 1025 480z"/>
<path d="M63723 10849 c-392 -57 -708 -261 -855 -554 -58 -115 -80 -210 -86
-366 -7 -221 30 -363 132 -518 58 -87 199 -222 306 -293 108 -72 314 -171 495
-238 530 -198 720 -385 719 -710 0 -237 -108 -412 -317 -515 -152 -75 -220
-88 -442 -89 -160 -1 -208 3 -303 22 -154 31 -307 82 -437 147 -61 30 -113 51
-116 47 -7 -8 -119 -369 -119 -383 0 -12 138 -80 228 -113 87 -31 232 -68 362
-93 137 -25 575 -26 705 0 201 40 363 102 499 190 201 132 335 309 402 532 36
121 46 366 19 496 -38 187 -117 331 -259 467 -153 147 -330 248 -671 382 -380
150 -539 249 -634 391 -138 207 -100 497 87 659 70 61 195 118 309 142 84 17
122 19 258 15 221 -8 386 -46 573 -133 42 -19 76 -33 77 -32 1 2 30 86 65 188
35 102 65 190 67 196 5 13 -110 62 -227 97 -102 31 -205 52 -320 66 -100 13
-428 12 -517 0z"/>
<path d="M80365 10834 c-16 -2 -80 -11 -141 -19 -557 -77 -1032 -364 -1312
-793 -211 -324 -316 -763 -283 -1191 55 -722 400 -1244 986 -1492 272 -115
523 -159 903 -159 373 0 668 46 1067 168 l60 18 3 872 2 872 -610 0 -610 0 0
-190 0 -190 375 0 375 0 0 -534 0 -535 -52 -19 c-136 -51 -369 -77 -621 -69
-267 8 -442 45 -633 136 -444 212 -700 621 -733 1174 -34 568 157 1025 542
1298 86 61 261 148 371 184 384 124 941 103 1311 -49 43 -17 62 -21 66 -13 4
7 30 90 59 186 28 96 54 180 56 186 10 25 -264 102 -503 141 -93 15 -599 29
-678 18z"/>
<path d="M61610 9778 c0 -1107 11 -1548 47 -1883 l6 -60 -90 176 c-248 480
-296 558 -1221 1974 l-530 810 -266 3 -266 2 0 -1795 0 -1795 83 0 c45 0 146
3 225 7 l142 6 0 1136 c0 1018 -5 1312 -25 1741 l-6 125 91 -174 c218 -416
319 -580 1146 -1861 l626 -970 244 0 244 0 0 1795 0 1795 -225 0 -225 0 0
-1032z"/>
<path d="M77560 9778 c0 -1107 11 -1548 47 -1883 l6 -60 -90 176 c-248 480
-296 558 -1221 1974 l-530 810 -266 3 -266 2 0 -1795 0 -1795 83 0 c45 0 146
3 225 7 l142 6 0 1136 c0 1018 -5 1312 -25 1741 l-6 125 91 -174 c218 -416
319 -580 1146 -1861 l626 -970 244 0 244 0 0 1795 0 1795 -225 0 -225 0 0
-1032z"/>
<path d="M65580 9637 c0 -949 3 -1184 14 -1277 67 -532 281 -883 651 -1065
198 -98 381 -135 664 -135 434 0 735 107 982 350 185 182 290 379 358 670 50
212 51 243 51 1471 l0 1149 -240 0 -240 0 0 -1042 c0 -574 -5 -1113 -10 -1198
-15 -260 -56 -425 -146 -602 -39 -75 -65 -110 -139 -183 -158 -159 -331 -226
-585 -229 -161 -1 -255 17 -375 73 -251 118 -403 345 -470 701 -29 156 -34
378 -35 1433 l0 1047 -240 0 -240 0 0 -1163z"/>
<path d="M69120 9010 l0 -1790 1030 0 1030 0 0 195 0 195 -790 0 -790 0 0
1595 0 1595 -240 0 -240 0 0 -1790z"/>
<path d="M70800 10600 l0 -200 565 0 565 0 0 -1590 0 -1590 240 0 240 0 0
1590 0 1590 565 0 565 0 0 200 0 200 -1370 0 -1370 0 0 -200z"/>
<path d="M73930 9010 l0 -1790 240 0 240 0 0 1790 0 1790 -240 0 -240 0 0
-1790z"/>
<path d="M2156 7459 l-496 -551 23 -28 c12 -15 235 -258 496 -539 l475 -511
823 0 c453 0 823 3 823 8 0 4 -212 252 -470 552 -463 536 -469 545 -451 565
215 242 921 1046 921 1050 0 3 -371 5 -825 5 l-824 0 -495 -551z"/>
<path d="M76353 5109 c-392 -57 -708 -261 -855 -554 -58 -115 -80 -210 -86
-366 -7 -221 30 -363 132 -518 58 -87 199 -222 306 -293 108 -72 314 -171 495
-238 530 -198 720 -385 719 -710 0 -237 -108 -412 -317 -515 -152 -75 -220
-88 -442 -89 -160 -1 -208 3 -303 22 -154 31 -307 82 -437 147 -61 30 -113 51
-116 47 -7 -8 -119 -369 -119 -383 0 -12 138 -80 228 -113 87 -31 232 -68 362
-93 137 -25 575 -26 705 0 201 40 363 102 499 190 201 132 335 309 402 532 36
121 46 366 19 496 -38 187 -117 331 -259 467 -153 147 -330 248 -671 382 -380
150 -539 249 -634 391 -138 207 -100 497 87 659 70 61 195 118 309 142 84 17
122 19 258 15 221 -8 386 -46 573 -133 42 -19 76 -33 77 -32 1 2 30 86 65 188
35 102 65 190 67 196 5 13 -110 62 -227 97 -102 31 -205 52 -320 66 -100 13
-428 12 -517 0z"/>
<path d="M60275 5094 c-16 -2 -80 -11 -141 -19 -557 -77 -1032 -364 -1312
-793 -211 -324 -316 -763 -283 -1191 55 -722 400 -1244 986 -1492 272 -115
523 -159 903 -159 373 0 668 46 1067 168 l60 18 3 872 2 872 -610 0 -610 0 0
-190 0 -190 375 0 375 0 0 -534 0 -535 -52 -19 c-136 -51 -369 -77 -621 -69
-267 8 -442 45 -633 136 -444 212 -700 621 -733 1174 -34 568 157 1025 542
1298 86 61 261 148 371 184 384 124 941 103 1311 -49 43 -17 62 -21 66 -13 4
7 30 90 59 186 28 96 54 180 56 186 10 25 -264 102 -503 141 -93 15 -599 29
-678 18z"/>
<path d="M73240 5089 c-246 -9 -575 -42 -652 -65 -17 -5 -18 -98 -18 -1775 l0
-1769 240 0 240 0 0 775 0 775 208 0 c322 -1 459 -19 587 -80 83 -39 147 -92
201 -164 96 -127 134 -231 223 -604 71 -297 129 -505 172 -619 l32 -83 255 0
c199 0 253 3 249 13 -73 165 -115 308 -231 772 -46 187 -100 381 -120 431 -94
234 -231 390 -414 471 l-56 25 81 34 c370 153 590 454 610 834 10 193 -32 383
-120 540 -57 101 -195 239 -302 301 -209 121 -419 172 -775 189 -102 5 -192 9
-200 8 -8 -1 -103 -4 -210 -9z m531 -383 c312 -56 509 -217 574 -471 21 -81
21 -254 0 -334 -48 -187 -162 -326 -341 -414 -167 -83 -249 -96 -636 -104
l-318 -6 0 656 0 655 71 12 c189 32 489 35 650 6z"/>
<path d="M57470 4038 c0 -1107 11 -1548 47 -1883 l6 -60 -90 176 c-248 480
-296 558 -1221 1974 l-530 810 -266 3 -266 2 0 -1795 0 -1795 83 0 c45 0 146
3 225 7 l142 6 0 1136 c0 1018 -5 1312 -25 1741 l-6 125 91 -174 c218 -416
319 -580 1146 -1861 l626 -970 244 0 244 0 0 1795 0 1795 -225 0 -225 0 0
-1032z"/>
<path d="M65910 4038 c0 -1107 11 -1548 47 -1883 l6 -60 -90 176 c-248 480
-296 558 -1221 1974 l-530 810 -266 3 -266 2 0 -1795 0 -1795 83 0 c45 0 146
3 225 7 l142 6 0 1136 c0 1018 -5 1312 -25 1741 l-6 125 91 -174 c218 -416
319 -580 1146 -1861 l626 -970 244 0 244 0 0 1795 0 1795 -225 0 -225 0 0
-1032z"/>
<path d="M52460 3270 l0 -1790 1040 0 1040 0 0 195 0 195 -800 0 -800 0 0 645
0 645 720 0 720 0 0 195 0 195 -720 0 -720 0 0 560 0 560 760 0 760 0 0 195 0
195 -1000 0 -1000 0 0 -1790z"/>
<path d="M62280 3270 l0 -1790 240 0 240 0 0 1790 0 1790 -240 0 -240 0 0
-1790z"/>
<path d="M67190 3270 l0 -1790 1040 0 1040 0 0 195 0 195 -800 0 -800 0 0 645
0 645 720 0 720 0 0 195 0 195 -720 0 -720 0 0 560 0 560 760 0 760 0 0 195 0
195 -1000 0 -1000 0 0 -1790z"/>
<path d="M69880 3270 l0 -1790 1040 0 1040 0 0 195 0 195 -800 0 -800 0 0 645
0 645 720 0 720 0 0 195 0 195 -720 0 -720 0 0 560 0 560 760 0 760 0 0 195 0
195 -1000 0 -1000 0 0 -1790z"/>
<path d="M1490 3116 c-272 -304 -495 -560 -495 -568 0 -8 221 -254 490 -546
l490 -530 833 -1 c634 -1 831 2 826 11 -4 6 -216 254 -471 550 -256 297 -463
545 -460 552 3 6 215 250 471 540 256 291 466 532 466 537 0 5 -354 9 -827 8
l-828 0 -495 -553z"/>
</g>
</svg>
  );
};

export default LogoSvg;
