import React from "react";
import SectionNEWS from "./SectionNews";
import LandingBanner from "./LandingBanner";
import { Helmet } from "react-helmet";
//import { PostDataType } from "data/types";
import {
  NEWS_POSTS,
} from "data/posts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Contact from "components/Contact/Contact";
import { NEWS_CATEGORIES } from "data/taxonomies";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
//
//const POSTS: PostDataType[] = NEWS_POSTS;
//
let NEWS_TABS = NEWS_CATEGORIES.map((category) => category.name)
NEWS_TABS.splice(0, 0, 'All')
//const NEWSFEED_POSTS = NEWS_POSTS.filter((_, i) => i >= 8 && i < 16); //TODO REMOVE


const PageHome: React.FC = () => {
  RouteChangeTracker();
  return (
    <div className="nc-PageHome relative">
      <Helmet>
        <title>Concrete Scanning &amp; Testing | Civil Engineering Consultants Melbourne</title>
      </Helmet>

      {/* ======== ALL SECTIONS ======== */}
      <div className="relative overflow-hidden">
      <LandingBanner
          href="/single-template-3/this-is-single-slug"
          youtubeID="qTsXfGVjm1w"
          //rightImg="https://images.pexels.com/photos/4666750/pexels-photo-4666750.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          rightImg='' //TODO Fix image allocation with component
          heading="Rapid Consulting Engineers"
          subHeading="Rapid Consulting Engineers is the forefront of the non-destructive testing industry for concrete scanning and concrete testing. Our specialised engineers go above and beyond priding themselves on their quality and expertise. Our investigation and testing services are backed by our years of technical experience in structural engineering and design. We guarantee you the best concrete scanning and testing solutions in the industry which gives you confidence to move forward with your project. Call us today!."
        />
        {/* ======== BG GLASS ======== */}
        <BgGlassmorphism />

        {/* ======= START CONTAINER ============= */}
        <div className="container relative">
          {/* === SECTION  === */}
          


          {/* === SECTION 4 === */}
          <SectionNEWS
            className="py-16 lg:py-28"
            posts={NEWS_POSTS}
            tabs={NEWS_TABS}
          />

        </div>
        {/* TODO ADD A COMPANIES WE WORKED FOR SLIDER */}

        <div className="container ">

          {/* === SECTION 14 === */}
          <Contact></Contact>

          {/* === SECTION 15 === */}
          {/* //TODO Add back videos with videos 
          <SectionVideos className="py-16 lg:py-28" /> */}

        </div>
        {/* ======= END CONTAINER ============= */}
      </div>
    </div>
  );
};

export default PageHome;
