import { FC, ReactNode } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import ButtonSecondary from "components/Button/ButtonSecondary";
import image from '../Images/rail-bridge-testing.JPG';
import {Linking} from 'react-native';
import CallNow from "components/CallNow/CallNow";

export interface LandingBannerProps {
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  href: string;
  youtubeID: string;
}
const LandingBanner: FC<LandingBannerProps> = ({
  heading,
  subHeading,
}) => {

  return (
    <div className="relative pb-20 md:py-32 lg:py-60 bg-black">
      <div className="flex w-full mb-10 md:w-1/2 xl:w-3/5 md:absolute md:right-0 md:top-0 md:bottom-0 md:mb-0">
        <div className="hidden md:block absolute top-0 left-0 bottom-0 w-40 from-black bg-gradient-to-r"></div>
        <img className="w-full h-full object-cover" src={image} alt="" />
      </div>
      <div className="container relative z-10 text-neutral-100">
        <div className="max-w-2xl">
          <h1 className="font-bold text-4xl md:text-5xl xl:text-6xl mt-3 md:!leading-[110%] ">
            {heading}
          </h1>
          <p className="max-w-lg mt-7 text-base lg:text-xl text-neutral-300 text-justify">
            {subHeading}
          </p>
          <div className="flex space-x-4 mt-11">
            <CallNow />
            <ButtonSecondary href='/contact-us/'>Contact Us</ButtonSecondary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
