export const RapidContact = {
    'Phone Number':'1300 972 743',
    'Email': 'info@rapidengineers.com.au',
    'Address': 'Unit 6, 83-87 Dover St, Richmond VIC 3121',
}

export const RapidCategories = {
    NDT: 1,
    concreteInvestigations: 2,
    labTesting: 3,
    structural: 4,
    forensic: 5,
    geoTech: 6,
}
