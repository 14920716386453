import { FC } from "react";
import SingleTitle from "components/SingleTitle/SingleTitle";
import { ANDTPageType } from "./PageANDT";
import { Helmet } from "react-helmet";

export interface ANDTHeaderProps {
  pageData: ANDTPageType;
  hiddenDesc?: boolean;
  metaActionStyle?: "style1" | "style2";
  titleMainClass?: string;
  className?: string;
}

const ANDTHeader: FC<ANDTHeaderProps> = ({
  pageData,
  titleMainClass,
  hiddenDesc = false,
  className = "",
}) => {
  const { desc, title } = pageData;

  return (
    <>
      <Helmet>
        <title>{title} || Rapid Consulting Engineers</title>
      </Helmet>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          <SingleTitle mainClass={titleMainClass} title={title} />
          {!!desc && !hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {desc}
            </span>
          )}
          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
          </div>
        </div>
      </div>
    </>
  );
};

export default ANDTHeader;
