import React, { InputHTMLAttributes } from "react";
import { Field } from 'formik';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  sizeClass?: string;
  fontClass?: string;
  rounded?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className = "",
      sizeClass = "h-11 px-4 py-3",
      fontClass = "text-sm font-normal",
      rounded = "rounded-full",
      children,
      type = "text",
      ...args
    },
    ref
  ) => {
    return (
      <Field
        ref={ref}
        type={type}
        // TODO fix selected colour orange and also make Textarea the same
        className={`block w-full border-neutral-200 focus:border-rapidOrange-900 focus:ring focus:ring-rapidOrange-900 focus:ring-opacity-50 bg-white dark:border-rapidOrange-900 dark:focus:ring-rapidOrange-900 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${rounded} ${fontClass} ${sizeClass} ${className}`}
        {...args}
      />
    );
  }
);

export default Input;
