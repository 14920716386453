import __posts from "./jsons/__posts.json";
import __postsGallery from "./jsons/__postsGallery.json";
import { NEWS_CATEGORIES } from "./taxonomies";
import { PostDataType } from "./types";
import { NEWS_TEAMS } from "./teams";

// FOR MAIN NEWS
const NEWS_POSTS = __posts.map((post): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = post.categoriesId.map(
    (id) => NEWS_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    team: NEWS_TEAMS.filter((user) => user.id === post.teamId)[0],
    categories: [categories[0]],
  } as PostDataType;
});

// FOR POST TYPE GALLERY
const NEWS_POSTS_GALLERY = __postsGallery.map((post): PostDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const categories = post.categoriesId.map(
    (id) => NEWS_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    team: NEWS_TEAMS.filter((user) => user.id === post.teamId)[0],
    categories: [categories[0]],
  } as PostDataType;
});


export { NEWS_POSTS, NEWS_POSTS_GALLERY};
