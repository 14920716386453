
import { ANDTPageType } from "containers/PageANDT/PageANDT";
import { 
  ANDTBody,
  GPRBody,
  UltrasonicBody,
  AsBuiltBody, 
  QualityControlBody, 
  DeteriorationBody, 
  StructuralBody,
  LabServicesBody,
  LabTestingBody,
  LabCompressiveStrengthBody,
  LabCarbonationBody,
} from "./ContentMain";

import { RapidCategories } from "containers/Helpers/rapidInfo";

export const ANDT: ANDTPageType[] = [
  {
    id: "1",
    featuredImage: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/gp8100.webp',
    avatarImage:
      "",
    title: "Advanced non-destructive testing",
    desc: "RCE is a technologically advanced company offering a wide range of concrete scanning services. We understand that there are differences in the requirements of each project; therefore, we design testing programs tailored to suit the needs of each specific client. With our testing program, you can be assured we will accurately obtain the information you require using the best-in-class equipment and expertise.",
    href: "/advanced-non-destructive-testing",
    content: "stuffffff",
    parentService: true,
    category: RapidCategories.NDT,
    jsxElement: ANDTBody()
    //TO EDIT BODY OF THIS PAGE GO TO CONTENTMAIN ADNTBody
    //TODO justify the desc content being used. 
  },
  {
    id: "2",
    featuredImage:
      "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/gp8800.webp",
      avatarImage:
      "",
    title: "GPR Scanning",
    desc: "Concrete Scanning Services Melbourne",
    href: "/advanced-non-destructive-testing/gpr-scanning",
    content: "",
    parentService: false,
    category: RapidCategories.NDT,
    jsxElement: GPRBody()
  },
  {
    id: "3",
    featuredImage: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/ultrasonicTesting.JPG',
      avatarImage:
      "",
    title: "Ultrasonic Testing",
    desc: "Ultrasonic is one of the most comprehensive methods of investigating concrete.",
    href: "/advanced-non-destructive-testing/ultrasonic-testing",
    content: "",
    parentService: false,
    category: RapidCategories.NDT,
    jsxElement: UltrasonicBody()
  },

    {
    id: "4",
    featuredImage: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/structural-investigations.JPG',
      avatarImage:
      "",
    title: "Structural Investigations",
    desc: "RCE specialises in all manners of Structural Investigations large or small.",
    href: "/structural-investigations",
    content: "",
    parentService: true,
    category: RapidCategories.concreteInvestigations,
    jsxElement: StructuralBody()
  },
  {
    id: "5",
    featuredImage: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/asBuilt.JPG",
      avatarImage:
      "",
    title: "As-Built Inspections and Detailing",
    desc: "We are dedicated to invetigating the as-built details of all construction.",
    href: "/structural-investigations/as-built-inspections-and-detailing",
    content: "",
    parentService: false,
    category: RapidCategories.concreteInvestigations,
    jsxElement: AsBuiltBody()
  },
  {
    id: "6",
    featuredImage: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/qualityControl.JPG',
      avatarImage:
      "",
    title: "Quality Control",
    desc: "Quality control is an important step for any project. QC can save money along with improve the build for the client",
    href: "/structural-investigations/quality-control",
    content: "",
    parentService: false,
    category: RapidCategories.concreteInvestigations,
    jsxElement: QualityControlBody()
  },
  {
    id: "7",
    featuredImage: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/deterioration.JPG",
      avatarImage:
      "",
    title: "Deterioration Assessments",
    desc: "Assessments are important to determine the life left within a structure",
    href: "/structural-investigations/deterioration-assessments",
    content: "",
    parentService: false,
    category: RapidCategories.concreteInvestigations,
    jsxElement: DeteriorationBody()
  },





  // {
  //   id: "8",
  //   featuredImage:
  //     "https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80",
  //     avatarImage:
  //     "",
  //   title: "Concrete Scanning",
  //   desc: "as a little blog back in 2002 covering student work and over time",
  //   href: "/structural-investigations/scanning",
  //   content: "",
  //   parentService: false,
  //   category: RapidCategories.concreteInvestigations,
  // },
  // {
  //   id: "9",
  //   featuredImage:
  //     "https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80",
  //     avatarImage:
  //     "",
  //   title: "Concrete Testing",
  //   desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  //   href: "/structural-investigations/testing",
  //   content: "",
  //   parentService: false,
  //   category: RapidCategories.concreteInvestigations,
  // },
  // {
  //   id: "10",
  //   featuredImage:
  //     "https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80",
  //     avatarImage:
  //     "",
  //   title: "Concrete Investigation",
  //   desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  //   href: "/structural-investigations/investigations",
  //   content: "",
  //   parentService: false,
  //   category: RapidCategories.concreteInvestigations,
  // },

  {
    id: "11",
    featuredImage: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/ucs.jpg',
      avatarImage:
      "",
    title: "Laboratory Services",
    desc: "RCE's state of the art laboratory services are there for all your concrete testing needs.",
    href: "/laboratory-services",
    content: "",
    parentService: true,
    jsxElement: LabServicesBody(),
    category: RapidCategories.labTesting,
  },
  {
    id: "12",
    featuredImage: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/concreteTesting.jpg",
      avatarImage:
      "",
    title: "Testing",
    desc: "RCE's state of the art laboratory services are there for all your concrete testing needs.",
    href: "/laboratory-services/testing",
    content: "",
    parentService: false,
    jsxElement: LabTestingBody(),
    category: RapidCategories.labTesting,
  },
  {
    id: "13",
    featuredImage: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/compressiveStrength.jpg",
      avatarImage:
      "",
    title: "Compressive Strength",
    desc: "RCE's state of the art laboratory services are there for all your concrete testing needs.",
    href: "/laboratory-services/compressive-strength",
    content: "",
    parentService: false,
    jsxElement: LabCompressiveStrengthBody(),
    category: RapidCategories.labTesting,
  },
  {
    id: "14",
    featuredImage:
      "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/carbonation.png",
      avatarImage:
      "",
    title: "Carbonation",
    desc: "RCE's state of the art laboratory services are there for all your concrete testing needs.",
    href: "/laboratory-services/carbonation",
    content: "",
    parentService: false,
    jsxElement: LabCarbonationBody(),
    category: RapidCategories.labTesting,
  },
];