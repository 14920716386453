import { SinglePageType } from "containers/PageSingle/PageSingle";
//import { ServiceType, TaxonomyType } from "data/types";
import { NEWS_CATEGORIES } from "./taxonomies";
import podcastImg from "images/podcast.jpg";


export const SINGLE: SinglePageType = {
  id: "eae0212192f63287e0c212",
  featuredImage:
    "https://images.unsplash.com/photo-1605487903301-a1dff2e6bbbe?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1957&q=80",
  title: "Quiet ingenuity: 120,000 lunches and counting",
  desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  date: "May 20, 2021",
  href: "/single/this-is-single-slug",
  readingTime: 6,
  team: {
    id: 10,
    firstName: "Mimi",
    lastName: "Fones",
    displayName: "Fones Mimi",
    email: "mfones9@canalblog.com",
    avatar: "",
    href: "/team/the-demo-team-slug",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Team Job",
  },
  categories: [
    {
      id: 1,
      name: "Garden",
      href: "/news/the-demo-news-slug",
      thumbnail:
        "https://images.unsplash.com/photo-1461354464878-ad92f492a5a0?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGdhcmRlbmluZ3xlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
      color: "pink",
      taxonomy: "category",
    },
    {
      id: 2,
      name: "Jewelry",
      href: "/news/the-demo-news-slug",
      thumbnail:
        "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
      color: "red",
      taxonomy: "category",
    },
  ],
  postType: "standard",
  tags: [NEWS_CATEGORIES[0], NEWS_CATEGORIES[1], NEWS_CATEGORIES[3]],
  content: "",
};
export const SINGLE_AUDIO: SinglePageType = {
  id: "ea21212f687e0c",
  featuredImage: podcastImg,
  title: "Programming Languages",
  desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  date: "May 20, 2021",
  href: "/single/this-is-single-slug",
  readingTime: 6,
  team: {
    id: 10,
    firstName: "Mimi",
    lastName: "Fones",
    displayName: "Fones Mimi",
    email: "mfones9@canalblog.com",
    avatar: "",
    href: "/team/the-demo-team-slug",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Team Job",
  },
  categories: [
    {
      id: 2,
      name: "Jewelry",
      href: "/news/the-demo-news-slug",
      thumbnail:
        "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
      color: "red",
      taxonomy: "category",
    },
  ],
  postType: "audio",
  audioUrl: "https://soundcloud.com/tacongtung/2a-6-co-don-danh-cho-ai-remix",
  tags: [NEWS_CATEGORIES[0], NEWS_CATEGORIES[1], NEWS_CATEGORIES[3]],
  content: "",
};

export const SINGLE_VIDEO: SinglePageType = {
  id: "ea21ac32ds-6c192f68dscx7e0c212",
  featuredImage:
    "https://images.pexels.com/photos/326900/pexels-photo-326900.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  title: "Julio Urías does it all as Dodgers sweep in San Francisco",
  desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  date: "May 20, 2021",
  href: "/single/this-is-single-slug",
  readingTime: 6,
  team: {
    id: 10,
    firstName: "Mimi",
    lastName: "Fones",
    displayName: "Fones Mimi",
    email: "mfones9@canalblog.com",
    avatar: "",
    href: "/team/the-demo-team-slug",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Team Job",
  },
  categories: [
    {
      id: 2,
      name: "Jewelry",
      href: "/news/the-demo-news-slug",
      thumbnail:
        "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
      color: "red",
      taxonomy: "category",
    },
  ],
  postType: "video",
  videoUrl: "https://www.youtube.com/watch?v=a6roH6RffnA",
  tags: [NEWS_CATEGORIES[0], NEWS_CATEGORIES[1], NEWS_CATEGORIES[3]],
  content: "",
};

export const SINGLE_GALLERY: SinglePageType = {
  id: "eae0e85fd226c192f68dscx7e220c",
  featuredImage:
    "https://images.pexels.com/photos/326900/pexels-photo-326900.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  title: "Julio Urías does it all as Dodgers sweep in San Francisco",
  desc: "We’re an online magazine dedicated to covering the best in international product design. We started as a little blog back in 2002 covering student work and over time",
  date: "May 20, 2021",
  href: "/single/this-is-single-slug",
  readingTime: 6,
  team: {
    id: 10,
    firstName: "Mimi",
    lastName: "Fones",
    displayName: "Fones Mimi",
    email: "mfones9@canalblog.com",
    avatar: "",
    href: "/team/the-demo-team-slug",
    desc: "There’s no stopping the tech giant. Apple now opens its 100th store in China.There’s no stopping the tech giant.",
    jobName: "Team Job",
  },
  categories: [
    {
      id: 2,
      name: "Jewelry",
      href: "/news/the-demo-news-slug",
      thumbnail:
        "https://images.unsplash.com/photo-1535632066927-ab7c9ab60908?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NjV8fGpld2Vscnl8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
      color: "red",
      taxonomy: "category",
    },
  ],
  postType: "gallery",
  galleryImgs: [
    "https://images.pexels.com/photos/7354442/pexels-photo-7354442.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/307008/pexels-photo-307008.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/753626/pexels-photo-753626.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/1118448/pexels-photo-1118448.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    "https://images.pexels.com/photos/372098/pexels-photo-372098.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
  ],
  tags: [NEWS_CATEGORIES[0], NEWS_CATEGORIES[1], NEWS_CATEGORIES[3]],
  content: "",
};
