import React, { FC } from "react";

export interface HeadBackgroundCommonProps {
  className?: string;
}

const HeadBackgroundCommon: FC<HeadBackgroundCommonProps> = ({
  className = "absolute h-[400px]",
}) => {
  return (
    <div
      className={`nc-HeadBackgroundCommon ${className} top-0 left-0 rounded-[40px] right-0 w-full bg-rapidOrange-900 dark:bg-rapidOrange-900`}
      data-nc-id="HeadBackgroundCommon"
    />
  );
};

export default HeadBackgroundCommon;
