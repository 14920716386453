import {
  OurServicesItem,
  OurNewsItem,
  NavItemType,
} from "components/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __ourServices from "./jsons/__ourServices.json";
import __ourNews from "./jsons/__ourNews.json";

const ourServices: OurServicesItem[] = [
  {
    id: ncNanoId(),
    image: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/gp8100.webp',
    title: "Non-Destructive Testing",
    href: "/advanced-non-destructive-testing",
    items: __ourServices.map((i) => ({
      id: ncNanoId(),
      href: `/advanced-non-destructive-testing/${i.NDT.replaceAll(' ', '-')}`,
      name: i.NDT,
      title: i.NDT.replaceAll(' ', '-')
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/structural-investigations.JPG',
    title: "Structural Investigations",
    href: "/structural-investigations",
    items: __ourServices.map((i) => ({
      id: ncNanoId(),
      href: `/structural-investigations/${i.Concrete.replaceAll(' ', '-')}`,
      name: i.Concrete,
      title: i.Concrete.replaceAll(' ', '-')
    })),
  },
  {
    id: ncNanoId(),
    image: 'https://rce-website-images.s3.ap-southeast-2.amazonaws.com/ucs.jpg',
    title: "Laboratory Services",
    href: "/laboratory-services",
    items: __ourServices.map((i) => ({
      id: ncNanoId(),
      href: `/laboratory-services/${i.Lab.replaceAll(' ', '-')}`,
      name: i.Lab,
      title: i.Lab.replaceAll(' ', '-')
    })),
  },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGFwcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Structural",
  //   items: __ourServices.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.Structural,
  //   })),
  // },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2l0eXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Forensic",
  //   items: __ourServices.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.Forensic,
  //   })),
  // },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1575328630189-440449ed8cd1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvbnRydWN0aW9ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Geo-Technical",
  //   items: __ourServices.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.GeoTech,
  //   })),
  // },
];

const ourNews: OurNewsItem[] = [
  {
    id: ncNanoId(),
    image: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/gp8100.webp",
    title: "Non-Destructive Testing",
    href: "/news/advanced-non-destructive-testing",
    items: __ourNews.map((i) => ({
      id: ncNanoId(),
      href: `/news/${i.NDT.replaceAll(' ', '-')}`,
      name: i.NDT,
      title: i.NDT.replaceAll(' ', '-')
    })),
  },
  {
    id: ncNanoId(),
    image: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/structural-investigations.JPG",
    title: "Structural Investigations",
    href: "/news/structural-investigations",
    items: __ourNews.map((i) => ({
      id: ncNanoId(),
      href: `/news/${i.Concrete.replaceAll(' ', '-')}`,
      name: i.Concrete,
      title: i.Concrete.replaceAll(' ', '-')
    })),
  },
  {
    id: ncNanoId(),
    image: "https://rce-website-images.s3.ap-southeast-2.amazonaws.com/ucs.jpg",
    title: "Laboratory Services",
    href: "/news/laboratory-services",
    items: __ourNews.map((i) => ({
      id: ncNanoId(),
      href: `/news/${i.Laboratory.replaceAll(' ', '-')}`,
      name: i.Laboratory,
      title: i.Laboratory.replaceAll(' ', '-')
    })),
  },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTl8fGFwcHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Structural",
  //   href: "/news/structural",
  //   items: __ourNews.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.Structural,
  //   })),
  // },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1480714378408-67cf0d13bc1b?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y2l0eXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Forensic",
  //   href: "/news/forensic",
  //   items: __ourNews.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.Forensic,
  //   })),
  // },
  // {
  //   id: ncNanoId(),
  //   image:
  //     "https://images.unsplash.com/photo-1575328630189-440449ed8cd1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fGNvbnRydWN0aW9ufGVufDB8fDB8fA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=60",
  //   title: "Geo-Technical",
  //   href: "/news/geo-technical",
  //   items: __ourNews.map((i) => ({
  //     id: ncNanoId(),
  //     href: "#",
  //     name: i.GeoTech,
  //   })),
  // },
];


export const NAVIGATION_BAR: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
  },
  {
    id: ncNanoId(),
    href: "/about-us/",
    name: "About Us",
  },
  {
    id: ncNanoId(),
    href: "/our-services/",
    name: "Our Services",
    type: "OurServices",
    OurServices: ourServices,
  },

  {
    id: ncNanoId(),
    href: "/career/",
    name: "Career",
  },
  {
    id: ncNanoId(),
    href: "/news",
    name: "News",
    type: "OurNews",
    OurNews: ourNews,
  },
  {
    id: ncNanoId(),
    href: "/contact-us/",
    name: "Contact Us",
  },
];
