import React, { FC } from "react";
import Landing from "containers/PageHome/Landing";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Contact from "components/Contact/Contact";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
export interface PageCareerProps {
  className?: string;
}


const PageCareer: FC<PageCareerProps> = ({ className = "" }) => {
  RouteChangeTracker();
  return (
    <div className={`nc-PageContact ${className}`} data-nc-id="PageContact">
      <Helmet>
        <title>Career || Rapid Consulting Engineers</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container relative py-16 lg:py-28 space-y-16 lg:space-y-28">
        <Landing
              className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-24 "
              heading='Rapid Consulting Engineers'
              subHeading='Do you want to be apart of our team?'
              blurb='Here at RCE, we acknowledge the roles that our employees play in taking us to where we are today. They are invaluable to us. As a growing company, we are always ready to expand our team. Are you dedicated, hardworking and ready to go extra miles to satisfy clients? If yes, you are the type of employee we are looking for. We have an array of careers to offer. Get in touch with us now'
              featuredImage='https://rce-website-images.s3.ap-southeast-2.amazonaws.com/career.jpg'
              imageAlt="b"
              >
        </Landing>
      <Contact></Contact>
      </div>
    </div>
  );
};

export default PageCareer;
