import {Linking} from 'react-native';
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import LayoutPage from "components/LayoutPage/LayoutPage";
import Textarea from "components/Textarea/Textarea";
import { Component } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { RapidContact } from 'containers/Helpers/rapidInfo';
import {
  Formik,
  Form,
} from 'formik';
import axios, { AxiosError } from 'axios';
import RouteChangeTracker from 'components/RouteChangeTracker/RouteChangeTracker';

const API_KEY = "f30fe0a827c631d702a4952acb543bc049a20c16e3ccef701fbc85878b24d0e6";
const EMAIL = {
  id: 'e54e7f10-dcbb-4625-8cd5-57b6b2fce3ec',
  emailAddress: 'e54e7f10-dcbb-4625-8cd5-57b6b2fce3ec@mailslurp.com'
}

export interface ContactProps {
  className?: string;
  heading?: string;
  subHeading?: string;
  blurb?: string;
  featuredImage?: string;
  imageAlt?: string;
}

interface MyFormValues {
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
}

export class Contact extends Component<ContactProps> {
  public constructor(props: ContactProps){
  super(props);
  }
  async sendEmail(data: MyFormValues){
    let response: string = ''
    await axios({
      method: "POST",
      url: `https://api.mailslurp.com/sendEmail?apiKey=${API_KEY}`,
      data: {
        senderId: EMAIL.id,
        to: 'info@rapidengineers.com.au',
        subject: `${data.name} has sent a request`,
        body: `Name: ${data.name} Email: ${data.email} Number: ${data.phoneNumber} Message: ${data.message}`,
      },
    }).catch((error: AxiosError) => {
      if(error.response?.status === 404 ){
        response = 'Failed to send email please give us a call.';
      }
    }).then(() => {
        if(response.length <= 10){
          response = 'Successfully sent email'
        }
    })
    return response;
  }
  render() {

    const initialValues: MyFormValues = { name: '', email: '', phoneNumber: '', message: '' };
  

  return (
    <LayoutPage
      subHeading="Drop us message and we will get back for you."
      headingEmoji=""
      heading="Contact us"
    >
      <div className="grid gap-8 lg:grid-cols-2">
				<div className="max-w-sm space-y-6">
					<div>
						{/* TODO add Object.entries to get rid of dupe code */}
						{/* TODO add hover mouse animation to click of phone number */}
						<h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">Phone Number</h3>
							<a className="mt-2 text-neutral-500 dark:text-neutral-400" href="" onClick={() => {
										Linking.openURL("tel:+611300972743")
							}}><span>{RapidContact['Phone Number']}</span></a>
					</div>
				<div>
					{/* TODO add Object.entries to get rid of dupe code */}
					{/* TODO add link to email to Contact Page */}
					<h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">Email</h3>
					
          <a className="mt-2 text-neutral-500 dark:text-neutral-400" href="" onClick={() => {
										Linking.openURL("mailto:info@rapidengineers.com.au")
							}}><span className="block mt-2 text-neutral-500 dark:text-neutral-400">{RapidContact['Email']}</span></a>
				</div>
				<div>
					{/* TODO add Object.entries to get rid of dupe code */}
					{/* TODO add link from button to google Maps */}
					<h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">Address</h3>
					<a href='https://goo.gl/maps/d6QdsPYi6nqGWFvx5' target="_blank" rel='noreferrer'><span className="block mt-2 text-neutral-500 dark:text-neutral-400">{RapidContact['Address']}</span></a>
				</div>
				{/* /<div>
				{/* TODO WHen Marketing WHen <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
						🌏 SOCIALS
				</h3>
				<SocialsList className="mt-2" /> }
				</div> */}
			</div>
        <div className="border border-neutral-100 dark:border-neutral-700 lg:hidden"></div>
        <div>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            //RouteChangeTracker(true, 'Contact')

            Promise.resolve(this.sendEmail(values))
            .then((response) => {
              alert(JSON.stringify(response, null, 2));
              actions.setSubmitting(false);
            })
            
          }}
        >
          <Form>
            <label htmlFor="name" className="block mt-1">
              <Label>Full Name</Label>
            </label>
            <Input id="name" name="name" placeholder="Full Name" type="text" className="mt-1" />
            

            <label htmlFor="email" className="block mt-1">
              <Label>Email</Label>
            </label>
            <Input id="email" name="email" placeholder="Email" type="text" className="mt-1" />
            
            <label htmlFor="phoneNumber" className="block mt-1">
              <Label>Phone Number</Label>
            </label>
            <Input id="phoneNumber" name="phoneNumber" placeholder="Phone Number" type="text" className="mt-1" />

            <label htmlFor="message" className="block mt-1">
              <Label>Message</Label>
            </label>
            <Textarea id="message" name="message" placeholder="Message" rows={6} className="mt-1" />

            <ButtonPrimary className="mt-2" type="submit">Send Message</ButtonPrimary>
          </Form>
          </Formik>
        </div>
        </div>
    </LayoutPage>
  );
  }
};

export default Contact;
