import Avatar from "components/Avatar/Avatar";
import { PostTeamType } from "data/types";
import { FC } from "react";
import { Link } from "react-router-dom";

export interface SingleTeamProps {
  team: PostTeamType;
}

const SingleTeam: FC<SingleTeamProps> = ({ team }) => {
  return (
    <div className="nc-SingleTeam flex">
      <Link to={team.href}>
        <Avatar
          imgUrl={team.avatar}
          userName={team.displayName}
          sizeClass="h-12 w-12 text-lg sm:text-xl sm:h-24 sm:w-24 "
          radius="rounded-xl"
        />
      </Link>
      <div className="flex flex-col ml-3 max-w-lg sm:ml-5">
        <span className="text-xs text-neutral-400 uppercase tracking-wider">
          WRITEN BY
        </span>
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          <Link to={team.href}>{team.displayName}</Link>
        </h2>
        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
          {team.desc}
          <Link className="text-primary-6000 font-medium ml-1" to={team.href}>
            Readmore
          </Link>
        </span>
      </div>
    </div>
  );
};

export default SingleTeam;
