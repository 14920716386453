import { FC } from "react";
import { ANDTPageType } from "./PageANDT";
// import SingleTeam from "./SingleTeam";

export interface ANDTContentProps {
  data: ANDTPageType;
}

const ANDTContent: FC<ANDTContentProps> = ({ data }) => {
  //const { tags } = data;
  //let body: JSX.Element;
  // if(data.parentService){
  //   if(data.jsxElement === 'ADNTBody'){
  //     body = <ADNTBody />
  //   }
  //   else if(data.jsxElement === 'LabBody'){
  //     body = <LabBody />
  //   }  
  //   else if(data.jsxElement === 'ConcreteBody'){
  //     body = <ConcreteBody />
  //   }
  //   else body = <div></div>
  // }
  // else {
  //   body = <div></div>
  // }

  return (
    <div className="nc-SingleContent space-y-10">
      {/* ENTRY CONTENT */}
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        {/* THIS IS THE DEMP CONTENT */}
        {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
        {data.jsxElement}
      </div>
    </div>
  );
};

export default ANDTContent;
