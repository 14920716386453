import __taxonomies from "./jsons/__taxonomies.json";
import { TaxonomyType } from "./types";

const NEWS_CATEGORIES: TaxonomyType[] = __taxonomies.map((item) => ({
  ...item,
  taxonomy: "category",
}));

// const NEWS_CATEGORIES : TaxonomyType[] = __taxonomies.map((item) => ({
//   ...item,
//   taxonomy: "tag",
// }));

export { NEWS_CATEGORIES};
