import {Linking} from 'react-native';
import { Transition } from "@headlessui/react";
import NcImage from "components/NcImage/NcImage";
import Headings from "components/Heading/Headings";
import { Fragment, Component } from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import CallNow from 'components/CallNow/CallNow';
import ContactUs from 'components/ContactUs/ContactUs';

export interface LandingProps {
  className?: string;
  heading: string;
  subHeading: string;
  blurb: string;
  featuredImage: string;
  imageAlt: string;
}

export class Landing extends Component<LandingProps> {
  public constructor(props: LandingProps){
  super(props);
  }
  render() {

  return (
    <div className={`nc-SectionLargeSlider`}>
      {!!this.props.heading && <Headings subHeading={this.props.subHeading}>{this.props.heading}</Headings>}
        <Transition
        appear={true}
        as="div"
        className={`nc-CardLarge1 relative flex flex-col-reverse md:flex-row justify-end ${this.props.className}`}
        show={true}
      >
        <div className="md:absolute z-10 md:left-0 md:top-1/2 md:transform md:-translate-y-1/2 w-full -mt-8 md:mt-0 px-3 sm:px-6 md:px-0 md:w-3/5 lg:w-1/2 xl:w-2/5">
          <Transition.Child
            as={Fragment}
            enter="transform nc-will-change-transform transition-all duration-500"
            enterFrom="translate-y-4 opacity-0"
            enterTo="translate-y-0 opacity-100"
          >
            <div className="p-4 sm:p-8 xl:py-14 md:px-10 bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg shadow-lg rounded-3xl space-y-3 sm:space-y-5 !border-opacity-0 --  nc-dark-box-bg">
              <div><p className="text-justify">{this.props.blurb}</p></div>
              <div className='space-x-4'>
                <CallNow />
                <ContactUs />
              </div>
            </div>
          </Transition.Child>
        </div>
        <Transition.Child
          as="div"
          className="w-full md:w-4/5 lg:w-2/3"
          enter="transform nc-will-change-transform transition-all duration-500 delay-200"
          enterFrom="translate-y-4 scale-105 opacity-0"
          enterTo="translate-y-0 scale-100 opacity-100"
        >
            {/* TODO CHANGE IMAGE */}
            <NcImage
              prevImageHorizontal
              containerClassName="aspect-w-16 aspect-h-12 sm:aspect-h-9 md:aspect-h-14 lg:aspect-h-10 2xl:aspect-h-9 relative"
              className="absolute inset-0 object-cover rounded-3xl"
              src={this.props.featuredImage ? this.props.featuredImage : undefined}
              alt={this.props.imageAlt}
            />
        </Transition.Child>
      </Transition>
        
    </div>
  );
  }
};

export default Landing;
