import React, { FC, useEffect } from "react";
import Glide from "@glidejs/glide";
import { ServiceType } from "data/types";
import ncNanoId from "utils/ncNanoId";
import CardServiceBox from "components/CardServiceBox/CardServiceBox";


export interface SectionSliderServicesProps {
  className?: string;
  services: ServiceType[];
}

const SectionSliderServices: FC<SectionSliderServicesProps> = ({
  className = "",
  services,
}) => {
  const UNIQUE_CLASS = "glide_" + ncNanoId();

  useEffect(() => {
    setTimeout(() => {
      new Glide(`.${UNIQUE_CLASS}`, {
        perView: 3,
        gap: 32,
        bound: true,
        breakpoints: {
          1280: {
            perView: 4,
          },
          1023: {
            gap: 24,
            perView: 3,
          },
          767: {
            gap: 20,
            perView: 2,
          },
          639: {
            gap: 20,
            perView: 2,
          },
          500: {
            gap: 20,
            perView: 1,
          },
        },
      }).mount();
    }, 100);
  }, []);

  return (
    <div className={`nc-SectionSliderServices ${className}`}>
      <div className={`${UNIQUE_CLASS}`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {services.map((item, index) => (
              <li key={index} className="glide__slide pb-12 md:pb-16">
                <CardServiceBox service={item} />
              </li>
            ))}
          </ul>
        </div>
        {/* <NextPrev
          btnClassName="w-12 h-12"
          containerClassName="justify-center"
        /> */}
      </div>
    </div>
  );
};

export default SectionSliderServices;
