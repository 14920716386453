import { FC } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Landing from "containers/PageHome/Landing";
import SubLanding from "containers/PageHome/SubLanding";
import Contact from "components/Contact/Contact";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  RouteChangeTracker();
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Rapid Consulting Engineers</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container relative  py-16 lg:py-28 space-y-16 lg:space-y-28">
        <Landing
              className="pt-10 pb-16  lg:pb-28 lg:pt-24 "
              heading='Rapid Consulting Engineers'
              subHeading='Leading experts in everything non-destructive testing and laboratory services'
              blurb='Rapid Consulting Engineers is a technologically advanced company offering a wide range of concrete scanning services. We understand that there are differences in the requirements of each project; therefore, we design a testing program tailored to suit the needs of each specific client. With our testing program, you can rest assured we will accurately obtain the information you require using best-in-class equipment and expertise.

              RCE is an industry-leading company that provides exceptional advanced non-destructive testing, forensic inspections, engineering design services, and geotechnical investigations. We have built a team of seasoned and committed specialist engineers who are ready to go extra miles to offer high-quality services to our highly distinguished clients.'
              featuredImage='https://rce-website-images.s3.ap-southeast-2.amazonaws.com/aboutUsEWP.jpg'
              imageAlt="b"
              >
        </Landing>
        <SubLanding
              className="pt-10 pb-16 md:py-16 lg:pb-28 lg:pt-24"
              heading='Rapid Consulting Engineers'
              subHeading='Leading experts in everything Non-Destructive Tesitng'
              blurb='Although we kicked off our services by delivering excellent engineering designs for low-to-midrise housing and commercial projects, we have undergone several inevitable changes over the years to increase the quality and types of services we offer. Today, we are one of the most reliable, efficient and topmost companies delivering advanced, concrete non-destructive testing service in the whole of Australia.

              With the aid of the experiences of our dedicated engineers, state-of-the-art technologies and advanced testing techniques, we provide unrivalled services. Without a doubt, we are the trailblazer that other companies in the non-destructive testing industry in Australia are following.'
              featuredImage='https://rce-website-images.s3.ap-southeast-2.amazonaws.com/aboutUsUnderground.JPG'
              imageAlt="b"
              >
        </SubLanding>
      <Contact></Contact>
      </div>
    </div>
  );
};

export default PageAbout;
