import { FC, ReactNode, useEffect } from "react";
import { ServiceType } from "data/types";
import { ANDT } from "data/services";
import ANDTContent from "./ANDTContent";
import ANDTRelatedPosts from "./ANDTRelatedPosts";
import { useAppDispatch } from "app/hooks";
import { changeCurrentPage } from "app/pages/pages";
import ANDTHeader from "./ANDTHeader";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
export interface PageANDTProps {
  className?: string;
}

export interface ANDTPageType extends ServiceType {
  content: string | ReactNode;
}

const PageANDT: FC<PageANDTProps> = ({
  className = "",
}) => {
  const dispatch = useAppDispatch();
  RouteChangeTracker();
  // UPDATE CURRENTPAGE DATA IN PAGEREDUCERS
  useEffect(() => {
    dispatch(changeCurrentPage({ type: "/our-services/", data: currentPage[0] }));
    return () => {
      dispatch(changeCurrentPage({ type: "/", data: {} }));
    };
  }, []);

  // let windowLocation = window.location.pathname.toLocaleLowerCase()
  //console.log("🚀 ~ file: PageANDT.tsx ~ line 32 ~ windowLocation", windowLocation)
  const currentPage = ANDT.filter((object) => { 
    // let splitLocation = windowLocation.split('/')
    // const arrayLength = splitLocation.length
    // if(!(splitLocation[arrayLength-1].length <= 1)){
    //   windowLocation = windowLocation + '/'
    // }
    
    return object.href.split('/').join('').toLocaleLowerCase() === window.location.pathname.split('/').join('').toLocaleLowerCase()

  })

  return (
    <>
      <div
        className={`nc-PageSingleTemplate3 ${className}`}
        data-nc-id="PageSingleTemplate3"
      >
        <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-black">
          {/* SINGLE HEADER */}
          <div className="dark container relative z-10">
            <div className="max-w-screen-md">
              <ANDTHeader
                metaActionStyle="style2"
                pageData={currentPage[0]}//TODO FIX THIS ARRAY SO IT JUST RETURNS AN OBJECT FROM THE ARRAY ABOVE
              />
            </div>
          </div>

          {/* FEATURED IMAGE */}
          <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
            <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-neutral-900 dark:from-black bg-gradient-to-r"></div>
            <img
              className="block w-full h-full object-cover"
              src={currentPage[0].featuredImage}
              alt=""
            />
          </div>
        </header>

        {/* SINGLE MAIN CONTENT */}
        <div className="container mt-10 mb-10">
          <ANDTContent data={currentPage[0]} />
        </div>

        {/* RELATED POSTS */}
        <ANDTRelatedPosts data={currentPage[0]}/>
      </div>
    </>
  );
};

export default PageANDT;
