import React, { FC } from "react";
import ModalCategories from "./ModalCategories";
import { NEWS_POSTS } from "data/posts";
import { PostDataType} from "data/types";
import { NEWS_CATEGORIES} from "data/taxonomies";

import NewsFilterListBox from "components/NewsFilterListBox/NewsFilterListBox";
import { Helmet } from "react-helmet";

import NcImage from "components/NcImage/NcImage";
import Card11 from "components/Card11/Card11";
import { RapidCategories } from "containers/Helpers/rapidInfo";
import Contact from "components/Contact/Contact";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";

export interface PageNewsProps {
  className?: string;
}


const PageNews: FC<PageNewsProps> = ({ className = "" }) => {
  //const PAGE_DATA: TaxonomyType = NEWS_CATEGORIES[0];
  RouteChangeTracker();

  const FILTERS = [
    { name: "Most Recent" },
    { name: "By Team" },
    { name: "Read Time" },
  ];

  //Tag and category have same data type - we will use one NEWS data
  const pathName = window.location.pathname.toLocaleLowerCase()
  let pathNameCategory = pathName.split('/').pop()
    if ( pathNameCategory === undefined || pathNameCategory.length <= 1 ) {
      let itemArray = pathName.split('/');
      pathNameCategory = itemArray[itemArray.length - 2];
    }
  
    // let pathName = window.location.pathname.toLocaleLowerCase()
    // console.log("🚀 ~ file: PageNews.tsx ~ line 45 ~ pathName", pathName)
    // let splitLocation = pathName.split('/')
    // const arrayLength = splitLocation.length
    // if(!(splitLocation[arrayLength-1].length <= 1)){
    //   pathName = pathName + '/'
    // }
  // let posts: PostDataType[] = []
  // if(pathName !== undefined && pathName.length !== 0){
  //   if(pathName === '/news/advanced-non-destructive-testing'){
  //     posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.NDT)
  //   }
  //   else if(pathName === '/news/structural-investigations'){
      
  //     posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.concreteInvestigations)
  //   }
  //   else if(pathName === '/news/laboratory-services'){
  //     posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.labTesting)
  //   }
  //   else {
  //     posts = NEWS_POSTS.filter((_, i) => i < 16);
  //   }   
  // }
  // else {
  //   posts = NEWS_POSTS.filter((_, i) => i < 16);
  // }   

  // const pathName = window.location.pathname.toLocaleLowerCase()
  // const pathNameCategory = pathName.split('/')
  let posts: PostDataType[] = []
  if(pathNameCategory !== undefined && pathNameCategory.length !== 0){
    if(pathNameCategory === 'advanced-non-destructive-testing'){
      posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.NDT)
    }
    else if(pathNameCategory === 'structural-investigations'){
      
      posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.concreteInvestigations)
    }
    else if(pathNameCategory === 'laboratory-services'){
      posts = NEWS_POSTS.filter((post) => post.categories[0].id === RapidCategories.labTesting)
    }
    else {
      posts = NEWS_POSTS.filter((_, i) => i < 16);
    }  
  }
  else {
    posts = NEWS_POSTS.filter((_, i) => i < 16);
  }   
  return (
    <div className={`nc-PageNews ${className}`} data-nc-id="PageNews">
      <Helmet>
        <title>News || Rapid Consulting Engineers</title>
      </Helmet>

      {/* HEADER */}
      <div className="w-full px-2 xl:max-w-screen-2xl mx-auto">
        <div className="rounded-3xl relative aspect-w-16 aspect-h-16 sm:aspect-h-9 lg:aspect-h-8 xl:aspect-h-6 overflow-hidden ">
          <NcImage
            containerClassName="absolute inset-0"
            src="https://rce-website-images.s3.ap-southeast-2.amazonaws.com/docklands.JPG"
            className="object-cover w-full h-full"
            alt='Docklands'
          />
          <div className="absolute inset-0 bg-black text-white bg-opacity-30 flex flex-col items-center justify-center">
            <h2 className="inline-block align-middle text-5xl font-semibold md:text-7xl ">
              News
            </h2>
            <span className="block mt-4 text-neutral-300">
              {posts.length} Articles
              {/* //TODO check article count */}
            </span>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <div>
          <div className="flex flex-col sm:items-center sm:justify-between sm:flex-row">
            <div className="flex space-x-2.5">
              <ModalCategories categories={NEWS_CATEGORIES} />
              {/* <ModalTags tags={NEWS_CATEGORIES} /> */}
            </div>
            <div className="block my-4 border-b w-full border-neutral-100 sm:hidden"></div>
            <div className="flex justify-end">
              <NewsFilterListBox lists={FILTERS} />
            </div>
          </div>

          {/* LOOP ITEMS */}
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
            {posts.map((post) => {
              return <Card11 key={post.id} post={post} />
            })}
          </div>

          {/* PAGINATIONS */}
          {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination />
            <ButtonPrimary>Show me more</ButtonPrimary>}
          </div> */}
        </div>

        {/* Top Trending Topics*/}
        {/* === SECTION 5 === */}
        {/* <div className="relative py-16">
          <BackgroundSection />
          <SectionGridCategoryBox
            categories={NEWS_CATEGORIES.filter((_, i) => i < 5)}
          />
          <div className="text-center mx-auto mt-10 md:mt-16">
            <ButtonSecondary>Show me more</ButtonSecondary>
          </div>
        </div> */}

        {/* === SECTION 5 === */}
        {/* <SectionSliderNewTeams
          heading="Our Team"
          subHeading=""
          teams={NEWS_TEAMS.filter((_, i) => i < 10)}
        /> */}
        <Contact />
      </div>
    </div>
  );
};

export default PageNews;
