import ButtonPrimary from "components/Button/ButtonPrimary";
import Heading2 from "components/Heading/Heading2";
import React from "react";
import { Linking } from "react-native";
import CallNow from "components/CallNow/CallNow";
import ContactUs from "components/ContactUs/ContactUs";
// This file has the main bodys for each parent category of our services.
//TODO NEED TO ADD CSS FOR UL LISTS so that we have orange dot points again


export const ANDTBody = () => {
  return (
    <>
    <p className='text-justify'>
      Having created a long-lasting partnership with Melbourne-based universities, we collaborate to use state-of-the-art technologies and proven theories to gather useful information in innovative ways. Through our collaborations, we have developed and commercialised methods for carbon fibre delamination testing, as well as single sided epoxy injection crack testing.
    </p>
    <div className='space-x-4'>
      <CallNow />
      <ContactUs />
      {/* TODO FIX THIS BUTTON LINK COLOURED */}
    </div>
    <p className='text-justify'>
      A few non-destructive testing programs developed by RCE include:
    </p>
    <ul>
      <li>Delamination testing on thin carbon fibre elements</li>
      <li>Single sided crack injection testing</li>
      <li>Grid scanning utilising computer numeric control (CNC)</li>
    </ul>
    <Heading2>TYPES OF INSPECTIONS</Heading2>
    <p className='text-justify'>
      Here at RCE, we have a team of seasoned engineers that are highly experienced in the testing of concrete, steel, and timber. Whether for the government, commercial or residential sectors, we work all around the clock to deliver the best services.
    </p>
    <p className='text-justify'>
      To provide our clients with exceptional services, we have developed standardised non-destructive testing procedures for:
    </p>
    <ul>
      <li>Compressive strength testing</li>
      <li>Measurements of the thickness of concrete elements</li>
      <li>Depths of existing foundations</li>
      <li>As-built inspections</li>
      <li>Underground pipe and service locating (utility scanning)</li>
      <li>Pavement and footpath inspections</li>
      <li>Kerb and channel testing</li>
      <li>Bridge and tunnel lining inspections</li>
      <li>Corrosion likelihood and rate</li>
      <li>Crack analysis such as depth, activity, and severity</li>
      <li>General concrete scanning</li>
      <li>Underwater ultrasonic pulse velocity testing</li>
      <li>Pool shell audits</li>
      <li>Relative moisture content of concrete and soil</li>
    </ul>
    <Heading2>TECHNOLOGIES</Heading2>
    <p className='text-justify'>
      Let’s service your needs with state-of-the-art technologies!
    </p>
    <p className='text-justify'>
      To ensure that we continue to be the industry-leader offering non-destructive testing services, our professionals continually learn about the most advanced technologies and techniques in our industry. With the aid of the experience and versatility of our specialists, we offer our clients unrivalled services and high-quality data.
    </p>

    <p className='text-justify'>
      A few of the technologies available at RCE include:
    </p>
    <ul>
      <li>Ultrasonic pulse velocity (UPV)</li>
      <li>Ultrasonic pulse echo (UPE)</li>
      <li>3D Ultrasonic tomograms</li>
      <li>Impact echo (IE)</li>
      <li>Impact rebound compressive tests</li>
      <li>Magnetic induction</li>
      <li>Ground penetrating radar (GPR)</li>
      <li>Eddy current – Cover Meter</li>
      <li>Spectral analysis of surface waves (SASW)</li>
      <li>Concrete penetrating radar (Concrete GPR)</li>
      <li>Sonic echo impulse response (SE/IR)</li>
    </ul>
    </>
  );
};
export const LabServicesBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      {/* TODO REMOVE BLUE FROM LINK TEXTS */}
      <p className='text-justify'>
        RCE Specialises in all concrete laboratory services and testing. If you require prompt and accurate laboratory testing we are here for you. We adhere to all australian standards and can help you determine the appropriate tests for your required outcomes.
      </p>
      <ul>
        <li className='no-underline'>
          <a className='no-underline' href='/laboratory-services/testing'>Laboratory Testing</a>
        </li>
        <li>
        <a className='no-underline' href='/laboratory-services/compressive-strength'>Laboratory Compressive Strength Testing</a>
        </li>
        <li>
        <a className='no-underline' href='/laboratory-services/carbonation'>Laboratory Carbonation Testing</a>
        </li>
      </ul>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
    </>
  );
};

export const LabTestingBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p className='text-justify'>
        RCE Specialises in all concrete laboratory services and testing. If you require prompt and accurate laboratory testing we are here for you. We adhere to all australian standards and can help you determine the appropriate tests for your required outcomes.
      </p>
      <ul>
        <li>
        <a className='no-underline' href='/laboratory-services/compressive-strength'>Laboratory Compressive Strength Testing</a>
        </li>
        <li>
        <a className='no-underline' href='/laboratory-services/carbonation'>Laboratory Carbonation Testing</a>
        </li>
      </ul>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
    </>
  );
};

export const LabCompressiveStrengthBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p className='text-justify'>
        RCE Specialises in all concrete laboratory services and testing. If you require prompt and accurate laboratory testing we are here for you. We adhere to all australian standards and can help you determine the appropriate tests for your required outcomes.
      </p>
      <ul>
        <li>
          <a className='no-underline' href='/laboratory-services/testing'>Laboratory Testing</a>
        </li>
        <li>
        <a className='no-underline' href='/laboratory-services/carbonation'>Laboratory Carbonation Testing</a>
        </li>
      </ul>
      <div className='space-x-4'>
        <CallNow /> 
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
    </>
  );
};

export const LabCarbonationBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p className='text-justify'>
        RCE Specialises in all concrete laboratory services and testing. If you require prompt and accurate laboratory testing we are here for you. We adhere to all australian standards and can help you determine the appropriate tests for your required outcomes.
      </p>
      <ul>
        <li>
          <a className='no-underline' href='/laboratory-services/testing'>Laboratory Testing</a>
        </li>
        <li>
        <a className='no-underline' href='/laboratory-services/compressive-strength'>Laboratory Compressive Strength Testing</a>
        </li>
      </ul>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
    </>
  );
};

export const StructuralBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p className='text-justify'>
        For professional structural investigations Melbourne specialists and incredible service in Melbourne, you can rely upon Rapid Consulting Engineers. We are a team of highly qualified, trained and experienced structural investigation experts servicing greater Melbourne region and surrounding suburbs. There are a number of reasons for choosing us for structural investigations in the greater Melbourne region. Our services include:
      </p>
      <p className='text-justify'>
        1. On-time, convenient investigations
      </p>
      <p className='text-justify'>
        Our group of experienced engineers can arrange an on-site structural investigation whenever the timing is suitable for you to identify and help with a number of structural issues.
      </p>
      <p className='text-justify'>
        2. Expert structural investigations report
      </p>
      <p className='text-justify'>
        We can finish a specialist structural investigation of your building structure and submit detailed reports along with our expert recommendations on the findings.
      </p>
      <p className='text-justify'>
        3. Expert engineers
      </p>
      <p className='text-justify'>
        Our structural investigations are carried out by experienced structural engineers who can help with the the structural design process and help evaluate your structure or undertaking requires.
      </p>
      <p className='text-justify'>
        4. Latest technology
      </p>
      <p className='text-justify'>
        At Rapid Consulting Engineers our main goal is to present solutions not problems. We utilize the latest and most advanced technologies that are available and are constantly upgrading to keep up with the market requirements.
      </p>
      <p className='text-justify'>
        5. One stop shop
      </p>
      <p className='text-justify'>
        We strive to offer an exhaustive range of engineering solutions from structural investigations to NDT testing and laboratory services.
      </p>
      <p className='text-justify'>
        In addition to our expert structural investigations service, you can rely on Rapid Consulting Engineers to deliver on professionalism with a guarantee to friendly and accommodating service. For the best structural investigations Melbourne, call and talk with our experts today.
      </p>
      <ul>
        <li>
          <a className='no-underline' href='/structural-investigations/As-Built-Inspections-and-Detailing'>As Built Inspections and Detailing</a>
        </li>
        <li>
        <a className='no-underline' href='/structural-investigations/Deterioration-Assessments'>Deterioration Assessments</a>
        </li>
        <li>
        <a className='no-underline' href='/structural-investigations/Quality-Control'>Quality Control</a>
        </li>
      </ul>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
    </>
  );
};

export const GPRBody = () => {
  return (
    <>
    <p className='text-justify'>
      Before cutting, coring, chasing or drilling into any concrete structure, you need to make sure that there isn’t any risk involved like hitting a power or services cable or any sub-surface feature in the structure that can cause damage to machinery or the structure itself. Even more, these could pose a threat to the machinery operator’s safety as well.
    </p>
    <p className='text-justify'>
      Rapid Consulting Engineers offers GPR concrete scanning Melbourne as the safest and most reliable method of examining and analysing concrete areas to detect and pinpoint hidden features without disturbing the structure itself.
    </p>
    <div className='space-x-4'>
      <CallNow />
      <ContactUs />
      {/* TODO FIX THIS BUTTON LINK COLOURED */}
    </div>
    <Heading2>Ground Penetrating Radar Scanning Services</Heading2>
    <p className='text-justify'>
      Ground Penetrating Radar or GPR concrete scanning Melbourne is a non-destructive technique of surveying that is fast and efficient and does not cause any delays in construction or intrudes on other people working onsite. Saving on time and resource, GPR concrete scanning Melbourne is also the most cost effective inspection technique available.
    </p>
    <p className='text-justify'>
      GPR concrete scanning Melbourne is the latest in NDT methodology replacing concrete x-ray technology, as it offers quicker results, safe for use in open spaces and provides a clear imagery that is far more accurate. Unlike x-ray where the film needs to be developed, GPR concrete scanning Melbourne produces images as you scan allowing the equipment handler to analyse them in real time and make informed decisions on the go. Another advantage that GPR concrete scanning Melbourne has over x-ray is that it is suitable for scanning larger areas.    
    </p>
    <p className='text-justify'>
      At Rapid Consulting Engineers, we use latest equipment and cutting edge GPR concrete scanning Melbourne technique to accurately and effectively perform Structural Investigations and evaluations. We are committed to helping our clients save time and money by providing safe, reliable and efficient GPR concrete scanning Melbourne services. We have extensive experience in handling all type of simple and complex projects and are your one stop destination for all your GPR concrete scanning Melbourne needs.    
    </p>
    <p className='text-justify'>
      We do not base our GPR Concrete Melbourne advice on what you want to hear, but rather on what’s best for your property.
    </p>
    </>
)};

export const UltrasonicBody = () => {
    return (
      <>
      <p className='text-justify'>
        Ultrasonic Concrete testing Melbourne or GPR (Ground penetrating Radar) is our strong point and risk management is our core interest. Cutting or boring into concrete without finding significant issues like pre or post tension wires, service pipelines and voids could possibly damage your worksite and cause unnecessary delays and reworks. To guarantee you don't waste a huge sum of money in repair works and professional hazard, Rapid Engineers offer Ultrasonic Concrete testing Melbourne for you.      
      </p>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>
      <Heading2>Our Services</Heading2>
      <p className='text-justify'>
        Rapid Engineers have practical experience in Ultrasonic Concrete testing Melbourne finding and mapping hidden components utilizing best in class hardware and trained professionals to offer most precise and speedy Ultrasonic Concrete testing Melbourne in the market.
      </p>
      <p className='text-justify'>
        Ultrasonic Concrete testing Melbourne is a non-destructive testing methodology and can detect:
      </p>
      <ul>
        <li>Live or dead power channels</li>
        <li>Water and gas utilities</li>
        <li>Fibre optic cables</li>
        <li>Post and pre-tension wires</li>
        <li>Steel beams</li>
        <li>Voids</li>
      </ul>
      <p className='text-justify'>
        We understand that accidents on a worksite can cost time and money, so to keep your site on schedule we deliver real time mapping results and also offer expert ultrasonic concrete testing Melbourne advice if needed.      
      </p>
      <p className='text-justify'>
        Please contact us before:
      </p>
      
      <ul>
        <li>Core Drilling</li>
        <li>Concrete Cutting</li>
        <li>Concrete Drilling</li>
        <li>Walls sawing and cutting</li>
        <li>Designing and planning</li>
        <li>Demolition</li>
      </ul>
      <p className='text-justify'>
        Situated in Richmond, we service the entire greater Melbourne region and australia wide. We offer our Ultrasonic Concrete testing Melbourne for residential as well as commercial projects, so don't delay to get in touch with us for a free quote or site visit.
      </p>
      </>
)};

export const AsBuiltBody = () => {
  return (
    <>
    <p className='text-justify'>
      Here at rapid consulting engineers we pride ourselves on promoting both quality control and as-built construction reviews so that you can determine the up most quality has been received during construction.  
    </p>
    <div className='space-x-4'>
      <CallNow />
      <ContactUs />
      {/* TODO FIX THIS BUTTON LINK COLOURED */}
    </div>
    </>
)};

export const QualityControlBody = () => {
  return (
    <>
    <p className='text-justify'>
      Here at rapid consulting engineers we pride ourselves on promoting both quality control and as-built construction reviews so that you can determine the up most quality has been received during construction.  
    </p>
    <div className='space-x-4'>
      <CallNow />
      <ContactUs />
      {/* TODO FIX THIS BUTTON LINK COLOURED */}
    </div>
    </>
)};
export const DeteriorationBody = () => {
  return (
    <>
    <p className='text-justify'>
      Here at rapid consulting engineers we can investigate the condition of your structure so that you can have peace of mind regarding the health and safety of the structure.  
    </p>
    <div className='space-x-4'>
      <CallNow />
      <ContactUs />
      {/* TODO FIX THIS BUTTON LINK COLOURED */}
    </div>
    </>
)};


export const LoremIpsumBody = () => {
  return (
    <>
      {/* THIS IS THE DEMP CONTENT */}
      {/* IF YOUR DATA IS JSON, YOU CAN USE render with html-react-parser (https://www.npmjs.com/package/html-react-parser) */}
      <p className='text-justify'>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
        officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
        distinctio veritatis sapiente, minima corrupti dolores necessitatibus
        suscipit accusantium dignissimos culpa cumque.
      </p>
      <div className='space-x-4'>
        <CallNow />
        <ContactUs />
        {/* TODO FIX THIS BUTTON LINK COLOURED */}
      </div>  
      <p className='text-justify'>
        It is a long established fact that a <strong>reader</strong> will be
        distracted by the readable content of a page when looking at its{" "}
        <strong>layout</strong>. The point of using Lorem Ipsum is that it has a
        more-or-less normal{" "}
        <a className='no-underline' href="/#" target="_blank" rel="noopener noreferrer">
          distribution of letters.
        </a>{" "}
      </p>
      <ol>
        <li>We want everything to look good out of the box.</li>
        <li>
          Really just the first reason, that's the whole point of the plugin.
        </li>
        <li>
          Here's a third pretend reason though a list with three items looks
          more realistic than a list with two items.
        </li>
      </ol>
      <h3>Typography should be easy</h3>
      <p className='text-justify'>
        So that's a header for you — with any luck if we've done our job
        correctly that will look pretty reasonable.
      </p>
      <p className='text-justify'>Something a wise person once told me about typography is:</p>
      <blockquote>
        <p className='text-justify'>
          Typography is pretty important if you don't want your stuff to look
          like trash. Make it good then it won't be bad.
        </p>
      </blockquote>
      <p className='text-justify'>
        It's probably important that images look okay here by default as well:
      </p>
      <figure>
        <img
          src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1045&q=80"
          alt="nc blog"
        />
        <figcaption>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Iure vel
          officiis ipsum placeat itaque neque dolorem modi perspiciatis dolor
          distinctio veritatis sapiente
        </figcaption>
      </figure>
      <p className='text-justify'>
        Now I'm going to show you an example of an unordered list to make sure
        that looks good, too:
      </p>
      <ul>
        <li>So here is the first item in this list.</li>
        <li>In this example we're keeping the items short.</li>
        <li>Later, we'll use longer, more complex list items.</li>
      </ul>
      <p className='text-justify'>And that's the end of this section.</p>
      <h2>Code should look okay by default.</h2>
      <p className='text-justify'>
        I think most people are going to use{" "}
        <a className='no-underline' href="https://highlightjs.org/">highlight.js</a> or{" "}
        <a className='no-underline' href="https://prismjs.com/">Prism</a> or something if they want to
        style their code blocks but it wouldn't hurt to make them look{" "}
        <em>okay</em> out of the box, even with no syntax highlighting.
      </p>
      <p className='text-justify'>
        What I've written here is probably long enough, but adding this final
        sentence can't hurt.
      </p>
      <pre>
        <code className="language-js">
          {`function tick() {
  const element = (
    <div>
      <h1>Hello, world!</h1>
      <h2>It is {new Date().toLocaleTimeString()}.</h2>
    </div>
  );
  ReactDOM.render(element, document.getElementById('root'));
} `}
        </code>
      </pre>
      <p className='text-justify'>Hopefully that looks good enough to you.</p>
      <h3>We still need to think about stacked headings though.</h3>
      <h4>
        Let's make sure we don't screw that up with <code>h4</code> elements,
        either.
      </h4>
      <p className='text-justify'>
        Phew, with any luck we have styled the headings above this text and they
        look pretty good.
      </p>
      <p className='text-justify'>
        Let's add a closing paragraph here so things end with a decently sized
        block of text. I can't explain why I want things to end that way but I
        have to assume it's because I think things will look weird or unbalanced
        if there is a heading too close to the end of the document.
      </p>
      <p className='text-justify'>
        What I've written here is probably long enough, but adding this final
        sentence can't hurt.
      </p>
    </>
  );
};
