import { FC } from "react";
import Headings from "components/Heading/Headings";
import { PostDataType } from "data/types";
import Card11 from "components/Card11/Card11";
import { NEWS_POSTS } from "data/posts";
import { ANDTPageType } from "./PageANDT";

export interface ServicesRelatedPostsProps {
  relatedPosts?: PostDataType[];
  data: ANDTPageType;
}

// DEMO DATA


const ServicesRelatedPosts: FC<ServicesRelatedPostsProps> = ({
  relatedPosts,
  data,
}) => {
  const newsRelated: PostDataType[] = NEWS_POSTS.filter((post) => post.categories[0].id === data.category )
  relatedPosts = newsRelated;
  return (
    <div className="relative bg-neutral-100 dark:bg-neutral-800 py-10 lg:py-10 mt-5 lg:mt-5">
      {/* RELATED  */}
      <div className="container">
        <div>
          <Headings
            className="mb-5 text-neutral-900 dark:text-neutral-50"
            subHeading=""
          >
            {data.title} News
          </Headings>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {relatedPosts.map((post) => (
              <Card11 key={post.id} post={post} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesRelatedPosts;
