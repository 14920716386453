import React from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Linking } from "react-native";
import RouteChangeTracker from "components/RouteChangeTracker/RouteChangeTracker";
import { useLocation } from "react-router-dom";
export interface CallNowProps {

}

const CallNow: React.FC<CallNowProps> = ({
}) => {
  return (
    <ButtonPrimary onClick={() => {
        //RouteChangeTracker(true, "CallNow");
        Linking.openURL("tel:+611300972743");
      }}>Call Now</ButtonPrimary>
  );
};

export default CallNow;
